import * as React from 'react'
import { useForm } from 'react-hook-form'

import { useAuth } from '@hooks/useAuth'
import NextLink from './NextLink'
import Animation from '@components/Animation'

export const LoginForm: React.FC = () => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm()
  const { signin } = useAuth()

  return (
    <main className="w-full max-w-sm">
      <form
        className="flex flex-col max-w-md space-y-4 bg-white rounded-lg"
        onSubmit={handleSubmit(signin)}
      >
        <div className="flex justify-center">
          <Animation
            className="w-[150px] h-[100px]"
            path="/animations/keyhero-flying.json"
          />
        </div>
        <h2 className="text-2xl text-center font-bold text-gray-900">
          Login to Keyhero
        </h2>
        <div className="flex flex-col space-y-2">
          <label
            className="block text-base font-bold text-slate-600"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className={`px-3 py-2 border rounded-md text-base ${
              errors.email
                ? 'border-pink-500 text-pink-600 focus:border-pink-500'
                : 'border-slate-300'
            }`}
            {...register('email', { required: true })}
            type="email"
          />
          {errors.email?.type === 'required' && (
            <p className="mt-2 text-pink-600 text-base">Email is required</p>
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex fex-col justify-between">
            <label
              className="block text-base font-bold text-slate-600"
              htmlFor="password"
            >
              Password
            </label>
            <NextLink href="/reset-password" className="text-base">
              Forgot your password?
            </NextLink>
          </div>
          <input
            className={`px-3 py-2 border rounded-md text-base ${
              errors.password
                ? 'border-pink-500 text-pink-600 focus:border-pink-500 placeholder:text-pink-600'
                : 'border-slate-300'
            }`}
            {...register('password', { required: true })}
            type="password"
          />
          {errors.password?.type === 'required' && (
            <p className="mt-2 text-pink-600 text-base">Password is required</p>
          )}
        </div>
        <button
          disabled={isSubmitting}
          className="px-6 py-2 font-bold text-white bg-blue-500 border-transparent rounded-lg shadow-lg hover:bg-blue-600"
          type="submit"
        >
          Continue
        </button>
      </form>
      {/* <p className="mt-8 text-base text-slate-600">
        No account?{' '}
        <Link href="/signup">
          <a>Sign up</a>
        </Link>
      </p> */}
    </main>
  )
}
