import type { NextPage } from 'next'

import { LoginForm } from '@components/LoginForm'

const LoginPage: NextPage = () => {
  return (
    <div className="min-h-full relative z-10 flex flex-1 flex-col items-center justify-center">
      <LoginForm />
    </div>
  )
}

export default LoginPage
