import * as React from 'react'
import { useContext } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { toast } from 'react-hot-toast'

import {
  RegistrationInputs,
  SigninInputs,
  useSignup,
  useSignin,
} from '@hooks/generated'
import { SessionContext } from '@contexts/SessionContext'

type SignupUserFn = (values: RegistrationInputs) => void
type SigninFn = (values: SigninInputs) => void
type UseAuthHook = () => {
  signupUser: SignupUserFn
  signin: SigninFn
}

export const useAuth: UseAuthHook = () => {
  const { setAuth } = useContext(SessionContext)
  const { push, query } = useRouter()
  const { mutateAsync: signup } = useSignup()
  const { mutateAsync: signinUser } = useSignin()

  const signupUser = React.useCallback<SignupUserFn>(
    async (values) => {
      try {
        const { res } = await signup({ inputs: { ...values } })
        if (res?.errors) {
          Sentry.captureException(res.errors)
          toast.error('An unexpected error occurred')
          throw new Error('Oops!')
        }
        if (res?.data) {
          setAuth(res.data)
          push(`/onboarding`)
        }
      } catch (error) {
        Sentry.captureException(error)
        toast.error('An unexpected error occurred')
        throw new Error('[useAuth:registration] Failed')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const signin = React.useCallback<SigninFn>(async (values) => {
    try {
      const { res } = await signinUser({ inputs: { ...values } })
      if (res?.errors) {
        Sentry.captureException(res.errors)
        toast.error('An unexpected error occurred')
        throw new Error('Oops!')
      }
      if (res?.data) {
        setAuth(res.data)
        const path = query?.from || '/learn'
        push(`/learn`)
      }
    } catch (error) {
      Sentry.captureException(error)
      toast.error('An unexpected error occurred')
      throw new Error('[useAuth:signIn] Failed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { signupUser, signin }
}
